import React from 'react';
import Header from '../components/header'
import Footer from '../components/footer';
import { Helmet } from "react-helmet";


const ThankYouPage = () => {

  return (
    <>
    <Header/>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Thank You - Productlabs</title>
        <link rel="canonical" href="https://productlabs.us/" />
        <meta property="og:title" content="Thank You - Productlabs" />
        <meta property="og:description" content="VETTED EXPERTS TO HELP EXECUTE PRODUCT BUILD" />
    </Helmet>
    <section className="thank-you-section">
        <div className="container">
            <h1 className="thank-you-heading">Thank You</h1>
            <p className="message">Thank you for contacting us. One of our executive will connect with you soon.</p>
            <a href="/" className="thank-you cta">Go Home</a>
        </div>
    </section>
    <Footer/>
    </>
  )
}

export default ThankYouPage